var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CContainer",
    { staticClass: "content-center min-vh-100" },
    [
      _c(
        "CRow",
        { staticClass: "justify-content-center" },
        [
          _c(
            "CCol",
            [
              _c(
                "CCard",
                {
                  staticClass: "p-4",
                  attrs: { "data-cy": "forget-password-card" }
                },
                [
                  _c("CCardHeader", {
                    directives: [
                      {
                        name: "t",
                        rawName: "v-t",
                        value: "pages.auth.ForgotPassword.title",
                        expression: "'pages.auth.ForgotPassword.title'"
                      }
                    ],
                    staticClass: "lead"
                  }),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CForm",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.handle($event)
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "form-group" }, [
                            _vm.message
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "alert alert-danger",
                                    attrs: { role: "alert" }
                                  },
                                  [_vm._v(" " + _vm._s(_vm.message) + " ")]
                                )
                              : _vm._e()
                          ]),
                          _c("ValidatedInput", {
                            attrs: {
                              placeholder: _vm.$t(
                                "pages.auth.ForgotPassword.placeholder"
                              ),
                              field: _vm.$v.form.email,
                              "data-cy": "email",
                              autocomplete: "email",
                              value: _vm.$v.form.email.$model
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.form.email,
                                  "$model",
                                  $event
                                )
                              }
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend-content",
                                fn: function() {
                                  return [_vm._v(" @ ")]
                                },
                                proxy: true
                              }
                            ])
                          }),
                          _c(
                            "CRow",
                            [
                              _c(
                                "CCol",
                                {
                                  staticClass: "text-left",
                                  attrs: { col: "12" }
                                },
                                [
                                  _c(
                                    "CButton",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        color: "primary",
                                        disabled: _vm.loading,
                                        type: "submit"
                                      }
                                    },
                                    [
                                      _c("span", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.loading,
                                            expression: "loading"
                                          }
                                        ],
                                        staticClass:
                                          "spinner-border spinner-border-sm"
                                      }),
                                      _c("span", {
                                        directives: [
                                          {
                                            name: "t",
                                            rawName: "v-t",
                                            value:
                                              "pages.auth.ForgotPassword.button",
                                            expression:
                                              "'pages.auth.ForgotPassword.button'"
                                          }
                                        ]
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }